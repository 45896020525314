import React, { Component } from "react";
import Menu from "../component/Common/menu";
import Home from "../component/Home";
import Footer from "../component/Common/footer";

export default class PageHome extends Component {
  constructor(props) {
    super(props);
    this.project = React.createRef();
    this.house = React.createRef();
    this.location = React.createRef();
    this.googlemap = React.createRef();
    this.register = React.createRef();
  }

  componentDidUpdate() {
    this.scrolllanding();
  }

  scrolllanding = () => {
    var _top = 0;
    switch (window.location.pathname) {
      case "/project":
        _top = this.project.current.offsetTop;
        break;
      case "/house":
        _top = this.house.current.offsetTop;
        break;
      case "/location":
        _top = this.location.current.offsetTop;
        break;
      case "/googlemap":
        _top = this.googlemap.current.offsetTop;
        break;
      case "/register":
        _top = this.register.current.offsetTop;
        break;
      default:
        _top = 0;
        break;
    }

    window.scroll({
      top: _top - 77,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div>
        <Menu PageHome={this} />
        <Home PageHome={this} />
        <Footer />
      </div>
    );
  }
}
