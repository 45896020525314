import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./style/util.scss";
import "./style/main.scss";
import PageHome from "./container/home";
import PageAbout from "./container/about";
import PageNotfound from "./component/Common/notfound";
import { Switch, Route } from "react-router-dom";

export default class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={PageHome} />
          <Route exact path="/project" component={PageHome} />
          <Route exact path="/house" component={PageHome} />
          <Route exact path="/location" component={PageHome} />
          <Route exact path="/googlemap" component={PageHome} />
          <Route exact path="/register" component={PageHome} />
          <Route exact path="/home" component={PageHome} />
          <Route exact path="/about" component={PageAbout} />
          <Route component={PageNotfound} />
        </Switch>
      </div>
    );
  }
}
