import React, { Component } from "react";
import { Container, Row, Col, Table } from "reactstrap";

export default class ComponentHome extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <section className="p-t-100">
          <Container className="py-5">
            <h1 className="text-center text-green2">ประวัติผู้สร้างโครงการ</h1>
            <p className="py-5">
              &emsp;&emsp;คุณไกรยศ
              ได้มีความคิดริเริ่มที่จะสร้างโครงการบ้านอยู่อาศัยที่สวยงาม ปลอดภัย
              ทันสมัย อบอุ่น ราคาคุ้มค่า มีทำเลที่ดี ใกล้แหล่งชุมชน
              ง่ายต่อการเดินทาง ภายใต้แนวคิดนี้จึงก่อให้เกิดเป็นโครงการ K Town
              อ.สัตหีบ จ.ชลบุรี ในเครือ Triple K Group ขึ้นมา
            </p>
            <Row>
              <Col md="5">
                <img
                  className="img-fluid"
                  src="./asset/images/ceo.jpg"
                  alt="ceo"
                />
              </Col>
              <Col md="7">
                <Table className="resume" borderless size="sm" responsive>
                  <thead>
                    <tr>
                      <td colSpan="2">
                        <h5 className="text-green2">การศึกษา</h5>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-nowrap">ปริญญาตรี :</td>
                      <td>คณะวิศวกรรมศาตร์ สาขาโยธา จุฬาลงกรณ์มหาวิทยาลัย</td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">ปริญญาโท :</td>
                      <td>
                        คณะบริหารธุรกิจ (YOUNG EXECUTIVE MBA)
                        สถาบัณบัณฑิตพัฒนาบริหารศาสตร์ (NIDA)
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <td colSpan="2">
                        <h5 className="pt-4 text-green2">ประสบการณ์การทำงาน</h5>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-nowrap">2531 - 2533 :</td>
                      <td>บริษัท วอลเซ็น เอ็นเตอร์ไพร์ซ จำกัด</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ประเภทธุรกิจ รับเหมาก่อสร้าง</td>
                    </tr>
                    <tr className="mb-5">
                      <td></td>
                      <td>ตำแหน่ง วิศวกรสนาม</td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">2533 - 2535 : </td>
                      <td>บริษัท เม็งราย นวรัฐ จำกัด</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ประเภทธุรกิจ รับเหมาก่อสร้าง</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ตำแหน่ง วิศวกรโครงการ</td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">2535 - 2539 :</td>
                      <td>บริษัท โฮมเพลสกรุ๊ป จำกัด</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ประเภทธุรกิจ อสังหาริมทรัพย์</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ตำแหน่ง ผู้ช่วยผู้จัดการโครงการ</td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">2540 - 2559 : </td>
                      <td>บริษัท เอล์มคอน จำกัด</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ประเภทธุรกิจ รับเหมาก่อสร้าง</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ตำแหน่ง ผู้จัดการโครงการ</td>
                    </tr>
                    <tr>
                      <td className="text-nowrap">2560 - ปัจจุบัน : </td>
                      <td>บริษัท วี อาร์ ดีเวลล็อปเปอร์ จำกัด</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ประเภทธุรกิจ อสังหาริมทรัพย์</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>ตำแหน่ง กรรมการผู้จัดการ</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="bg-green0 p-t-100 p-b-100">
          <Container className="py-5">
            <h1 className="text-center text-green2">ผลงานที่ผ่านมา</h1>
            <p className="py-3">
              &emsp;&emsp;คุณไกรยศ กรรมการผู้จัดการบริษัท VR Developer จำกัด
              มีประสบการณ์การรับเหมาก่อสร้างมามากมาย
              โครงการส่วนใหญ่จะตั้งอยู่ที่ อ.นาเกลือ จ.ชลบุรี
              และมีบางส่วนที่ตั้งอยู่ใน เชียงใหม่ และ กรุงเทพมหานคร
              โดยผลงานโครงการที่ผ่านมา มีดังนี้
            </p>
          </Container>

          <div className="pb-5">
            <Container className="py-4">
              <h5 className="text-green2">โรงแรม The Zign ชลบุรี</h5>
            </Container>
            <div className="bg-award1">
              <Container className="py-5">
                <img
                  className="img-fluid"
                  src="./asset/images/the-zign-2.png"
                  alt="the-zign-2"
                />
              </Container>
            </div>
          </div>

          <div className="pb-5">
            <Container className="py-4 text-right">
              <h5 className="text-green2">
                โครงการ อาคาร TSix5 / TSix5.25 / TSix Phenomenal ชลบุรี
              </h5>
            </Container>
            <div className="bg-award2">
              <Container className="py-5">
                <img
                  className="img-fluid"
                  src="./asset/images/tsix.png"
                  alt="tsix"
                />
              </Container>
            </div>
          </div>

          <div className="pb-5">
            <Container className="py-4">
              <h5 className="text-green2">โรงแรม The Way ชลบุรี</h5>
            </Container>
            <div className="bg-award3">
              <Container className="pb-5">
                <img
                  className="img-fluid"
                  src="./asset/images/the-way.png"
                  alt="the-way"
                />
              </Container>
            </div>
          </div>

          <div className="pb-5">
            <Container className="py-4">
              <h5 className="text-green2">โรงแรม Long Beach ชลบุรี</h5>
            </Container>
            <div className="bg-award1">
              <Container className="py-5">
                <img
                  className="img-fluid"
                  src="./asset/images/long-beach.png"
                  alt="long-beach"
                />
              </Container>
            </div>
          </div>

          <div className="pb-5">
            <Container className="py-4 text-right">
              <h5 className="text-green2">โรงแรม เชียงใหม่ Hill เชียงใหม่</h5>
            </Container>
            <div className="bg-award4">
              <Container className="py-5">
                <img
                  className="img-fluid"
                  src="./asset/images/chiangmai-hill.png"
                  alt="chiangmai-hill"
                />
              </Container>
            </div>
          </div>

          <div className="pb-5">
            <Container className="py-4">
              <h5 className="text-green2">อาคารทิพยประกันภัย พระราม 9 กทม.</h5>
            </Container>
            <div className="bg-award5">
              <Container className="py-5">
                <img
                  className="img-fluid"
                  src="./asset/images/tipaya-insurance.png"
                  alt="tipaya-insurance"
                />
              </Container>
            </div>
          </div>

          <div className="pb-5">
            <Container className="py-4">
              <h5 className="text-green2">อาคาร Capital Residence ทองหล่อ</h5>
            </Container>
            <div className="bg-award6">
              <Container className="pb-5">
                <img
                  className="img-fluid"
                  src="./asset/images/capital-residence.png"
                  alt="capital-residence"
                />
              </Container>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
