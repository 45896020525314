import React, { Component } from "react";
import "./style.css";

export default class Loading extends Component {
  render() {
    return (
      <div className={"loading " + (this.props.loading ? "d-block" : "d-none")}>
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
