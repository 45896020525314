import React, { Component } from "react";
import { Col, Button } from "reactstrap";
import { NavLink, Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <section className="bg-brown py-2"></section>

        <section className="bg-brown text-white">
          <div className="text-center py-5">
            <h2 className="pb-3">สนใจลงทะเบียน</h2>
            <NavLink exact to="/register">
              <Button color="light" className="px-5 py-3">
                คลิก
              </Button>
            </NavLink>
          </div>
        </section>

        <section className="text-center text-white">
          <div className="d-flex flex-wrap bg-green3">
            <Col xl="4" className="py-4 py-xl-5 px-5">
              <h3>
                <a href="tel:033126411">
                  <i className="fas fa-phone pr-3" />
                  033-126411
                </a>
              </h3>
            </Col>
            <Col xl="4" className="py-4 py-xl-5 px-5">
              <h3>
                <a href="tel:0994197941">
                  <i className="fas fa-phone pr-3" />
                  099-4197941
                </a>
              </h3>
            </Col>
            <Col xl="4" className="py-4 py-xl-5 px-5">
              <h3>
                <a href="tel:0994997914">
                  <i className="fas fa-phone pr-3" />
                  099-4497914
                </a>
              </h3>
            </Col>
          </div>

          <hr className="bg-white my-0" />
          <div className="d-flex flex-wrap py-5 bg-green3">
            <Col md="12">
              <h3>
                <a href="mailto:VRktown@gmail.com">
                  <i className="fas fa-envelope pr-3" />
                  VRktown@gmail.com
                </a>
              </h3>
            </Col>
          </div>

          <hr className="bg-white my-0" />
          <div className="d-flex flex-wrap">
            <Col md="6" className="py-5 bg-green3 border-right border-bottom">
              <h4>
                <Link to="/">www.triplekgroup.com</Link>
              </h4>
            </Col>
            <Col md="6" className="py-5 bg-green3 border-bottom">
              {/* <div className="d-flex justify-content-center align-items-center">
                                <h4 className="pr-3">FOLLOW US</h4>
                                <a href="https://www.facebook.com/" className="nav-link px-1" target="_blank" rel="noopener noreferrer"><img src="./asset/images/facebook.png" width="40px" alt="facebook" /></a>
                                <a href="https://www.instagram.com/" className="nav-link px-1" target="_blank" rel="noopener noreferrer"><img src="./asset/images/instagram.png" width="40px" alt="instagram" /></a>
                                <a href="https://www.twitter.com/" className="nav-link px-1" target="_blank" rel="noopener noreferrer"><img src="./asset/images/twitter.png" width="40px" alt="twitter" /></a>
                            </div> */}
              <div className="d-flex justify-content-center align-items-center">
                <a
                  href="https://line.me/th/"
                  className="nav-link pr-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="./asset/images/line.png" width="40px" alt="line" />
                </a>
                <h4 className="pr-3">LINE ID : VRktown</h4>
              </div>
            </Col>
          </div>
          <div className="bg-yellow py-1"></div>
          <div className="py-3 bg-green4">
            <p>
              COPYRIGHT <i className="far fa-copyright" aria-hidden="true"></i>{" "}
              2019, VR DEVELOPMENT CO, LTD.
            </p>
          </div>
        </section>
      </div>
    );
  }
}
