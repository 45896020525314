import React, { Component } from "react";
import Menu from "../component/Common/menu";
import About from "../component/About";
import Footer from "../component/Common/footer";

export default class PageAbout extends Component {
  render() {
    return (
      <div>
        <Menu />
        <About />
        <Footer />
      </div>
    );
  }
}
