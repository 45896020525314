import Joi from "joi-browser";

const param = Joi.object().keys({
  firstname: Joi.string().label("กรุณาใส่ชื่อของคุณ"),
  lastname: Joi.string().label("กรุณาใส่นามสกุลของคุณ"),
  tel: Joi.number().label("กรุณาใส่เบอร์โทรศัพท์ของคุณ"),
  email: Joi.string().email({ minDomainAtoms: 2 }).label("กรุณาใส่อีเมลของคุณ"),
  date_register: Joi.object().label("กรุณาเลือกวันที่ขอเข้าชม"),
  message: Joi.string().label("กรุณาใส่ข้อความของคุณ"),
  recaptcha: Joi.string().label("กรุณาคลิกยืนยันว่าคุณไม่ใช่บอท"),
});

export function Validate(obj) {
  return Joi.validate(obj, param);
}
