import React, { Component } from "react";
import "./style.scss";
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="top-menu">
        <Navbar
          fixed="top"
          color="light"
          light
          expand="lg"
          className="shadow-lg"
        >
          <Container>
            <NavLink to="/">
              <img
                className="img-fluid"
                src="./asset/images/logo.png"
                alt=""
                width="60px"
              />
            </NavLink>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    exact
                    to="/"
                    className="nav-link"
                    activeClassName="menu-active"
                  >
                    หน้าแรก
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavHashLink
                    exact
                    to="/project"
                    className="nav-link"
                    activeClassName="menu-active"
                  >
                    ข้อมูลโครงการ
                  </NavHashLink>
                </NavItem>
                <NavItem>
                  <NavHashLink
                    exact
                    to="/house"
                    className="nav-link"
                    activeClassName="menu-active"
                  >
                    แบบบ้าน
                  </NavHashLink>
                </NavItem>
                <NavItem>
                  <NavHashLink
                    exact
                    to="/location"
                    className="nav-link"
                    activeClassName="menu-active"
                  >
                    ที่ตั้งโครงการ
                  </NavHashLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    exact
                    to="/about"
                    className="nav-link"
                    activeClassName="menu-active"
                  >
                    ประวัติผู้สร้าง
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavHashLink
                    exact
                    to="/register"
                    className="nav-link"
                    activeClassName="menu-active"
                  >
                    ลงทะเบียน
                  </NavHashLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
