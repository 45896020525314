import axios from 'axios'

const Service = {
  SendMail(e) {
    return axios.post('https://www.triplekgroup.com/Sendmail/index.php', e, { headers: { 'Content-Type': 'application/json' } })
  }, gethouseplan() {
    return axios.get('./asset/api/houseplan.json')
  },
}

export default Service