import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Alert,
  FormGroup,
  Button,
  Table,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import ReCAPTCHA from "react-google-recaptcha";
import { Validate } from "../Validate";
import swal from "sweetalert";
import Loading from "../Loading";
import Service from "../../service.js";
//import Gallery from './Gallery'
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
// import Swiper from 'react-id-swiper'
// import "swiper.min.css";
import Lightbox from "react-images";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

registerLocale("th", th);

const recaptchaRef = React.createRef();

// const GALLERY_IMAGES = [
//     { image_full: './asset/images/gallery/S__71614466.jpg', caption: 'รูปที่ 1', orientation: 'square', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614467.jpg', caption: 'รูปที่ 2', orientation: 'landscape', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614468.jpg', caption: 'รูปที่ 3', orientation: 'landscape', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614469.jpg', caption: 'รูปที่ 4', orientation: 'landscape', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614470.jpg', caption: 'รูปที่ 5', orientation: 'landscape', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614471.jpg', caption: 'รูปที่ 6', orientation: 'square', useForDemo: true, float: 'right' },
//     { image_full: './asset/images/gallery/S__71614472.jpg', caption: 'รูปที่ 7', orientation: 'landscape', useForDemo: true, float: 'right' },
//     { image_full: './asset/images/gallery/S__71614473.jpg', caption: 'รูปที่ 8', orientation: 'landscape', useForDemo: true, float: 'right' },
//     { image_full: './asset/images/gallery/S__71614474.jpg', caption: 'รูปที่ 9', orientation: 'landscape', useForDemo: true, float: 'right' },
//     { image_full: './asset/images/gallery/S__71614476.jpg', caption: 'รูปที่ 10', orientation: 'landscape', useForDemo: true, float: 'right' },
//     // { image_full: './asset/images/gallery/S__71614477.jpg', caption: 'รูปที่ 11', orientation: 'square', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614478.jpg', caption: 'รูปที่ 12', orientation: 'landscape', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614479.jpg', caption: 'รูปที่ 13', orientation: 'landscape', useForDemo: true, float: 'left' },
//     { image_full: './asset/images/gallery/S__71614480.jpg', caption: 'รูปที่ 14', orientation: 'landscape', useForDemo: true, float: 'left' },
// ]

const MAP_IMAGES = [
  {
    src: "./asset/images/map-image/K-town_map-02.png",
    caption: (
      <a href="./asset/images/map-image/K-town_map-02.png" download>
        <i className="fa fa-download" aria-hidden="true">
          <span className="pl-2">Download</span>
        </i>
      </a>
    ),
  },
];

// const params = {
//     slidesPerView: 1,
//     autoplay: {
//         delay: 5000,
//         disableOnInteraction: false
//     },
//     rebuildOnUpdate: true,
//     shouldSwiperUpdate: true,
//     navigation: {
//         nextEl: '.swiper-button-next',
//         prevEl: '.swiper-button-prev'
//     },
//     pagination: {
//         el: '.swiper-pagination',
//         clickable: true
//     },
//     renderPrevButton: () => <div><i className="fas fa-chevron-circle-left fa-2x text-white swiper-button-prev pl-3" /></div>,
//     renderNextButton: () => <div><i className="fas fa-chevron-circle-right fa-2x text-white swiper-button-next pr-5" /></div>,
// }

export default class ComponentHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxMapIsOpen: false,
      currentlightboxMap: 0,
      loading: false,
      alert: null,
      data: {
        firstname: "",
        lastname: "",
        tel: "",
        email: "",
        message: "",
        recaptcha: "",
        date_register: null,
      },
      valid: {},
      select: "0",
      items: [],
      item_images: [],
      modal: false,
    };
  }

  componentDidMount() {
    Service.gethouseplan()
      .then((response) => {
        this.setState({
          loading: false,
          items: response.data.data,
          item_images: response.data.data[0].images,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidUpdate() {
    console.clear();
  }

  date_registerChange = (date) => {
    this.setState({
      data: {
        firstname: this.state.data.firstname,
        lastname: this.state.data.lastname,
        tel: this.state.data.tel,
        email: this.state.data.email,
        message: this.state.data.message,
        recaptcha: this.state.data.recaptcha,
        date_register: date,
      },
    });
  };

  onDismiss = () => {
    this.setState({ alert: null });
  };

  handleEnter = (e) => {
    if (e.nativeEvent.keyCode === 13) {
      this.sendmail();
    }
  };

  getrecaptcha = (value) => {
    this.setState({
      data: {
        firstname: this.state.data.firstname,
        lastname: this.state.data.lastname,
        tel: this.state.data.tel,
        email: this.state.data.email,
        message: this.state.data.message,
        date_register: this.state.data.date_register,
        recaptcha: value,
      },
    });
  };

  valid = (e) => {
    this.onDismiss();
    const _data = this.state.data;
    _data[e.target.name] = e.target.value;

    const validate = Validate(this.state.data);
    if (validate.error !== null) {
      for (var i = 0; i < validate.error.details.length; i++) {
        this.setState({
          data: this.state.data,
          valid: { [validate.error.details[i].path[0]]: true },
        });
      }
    } else {
      this.setState({ data: this.state.data, valid: {} });
    }
  };

  sendmail = () => {
    const _data = {
      firstname: this.state.data.firstname,
      lastname: this.state.data.lastname,
      tel: this.state.data.tel,
      email: this.state.data.email,
      message: this.state.data.message,
      recaptcha: this.state.data.recaptcha,
      date_register: {
        datetime:
          this.state.data.date_register &&
          this.state.data.date_register.toLocaleString(),
        datetime_string: this.dateregister && this.dateregister.value,
      },
    };
    const validate = Validate(_data);

    if (validate.error === null) {
      this.setState({ loading: true });
      Service.SendMail(_data)
        .then((responese) => {
          swal({
            title: "สำเร็จ",
            text: "คุณส่งข้อความเรียบร้อยแล้ว",
            icon: "success",
          });
          this.onDismiss();
          this.setState({
            valid: {},
            data: {
              firstname: "",
              lastname: "",
              message: "",
              email: "",
              tel: "",
              recaptcha: "",
              date_register: new Date(),
            },
            loading: false,
          });
        })
        .catch((error) => {
          console.dir(error);
          swal({
            title: "เกิดข้อผิดพลาด",
            text: "เกิดข้อผิดพลาดขณะเรียกข้อมูลจากเซิร์ฟเวอร์",
            icon: "error",
            buttons: false,
          });
          this.setState({ loading: false });
        });
    } else {
      for (var i = 0; i < validate.error.details.length; i++) {
        this.setState({
          data: this.state.data,
          valid: { [validate.error.details[i].path[0]]: true },
        });
      }
      this.setState({ alert: validate.error.details[0].context.label });

      this.alert_danger &&
        window.scroll({
          top: this.alert_danger.offsetTop - 150,
          behavior: "smooth",
        });
    }
  };

  renderMarkers(map, maps) {
    new maps.Marker({
      position: { lat: 12.705306, lng: 100.892667 },
      map,
      title: "KKTOWN CO, LTD.",
    });
    this.props.PageHome.scrolllanding();
  }

  InputDatePicker = ({ value, onClick }) => {
    return (
      <input
        ref={(ref) => (this.dateregister = ref)}
        className="input-box"
        placeholder="นัดหมายเยี่ยมชม"
        readOnly={true}
        value={value}
        onClick={onClick}
      />
    );
  };

  changehouseplan = (e) => {
    this.setState({
      select: e.target.value,
      item_images: this.state.items[e.target.value].images,
    });
  };

  toggle = () => {
    this.setState((prevState) => ({ modal: !prevState.modal }));
  };

  render() {
    return (
      <div>
        <Loading loading={this.state.loading} />
        <section className="p-t-56">
          <div className="bg-banner">
            <Row>
              <Col className="ab-c-m text-center text-yellow">
                <h1 className="pb-3 font-weight-bold">K TOWN</h1>
                <h1 className="pb-5" style={{ lineHeight: 1.4 }}>
                  สวยทันสมัย อยู่ได้ทุก Generation
                  <br />
                  ใกล้สี่แยกเจ สัตหีบ
                </h1>
                <h4 className="text-white">เริ่มต้น 1.89 ล้านบาท</h4>
              </Col>
            </Row>
          </div>
        </section>

        <section className="bg-image2" ref={this.props.PageHome.register}>
          <div className="bg-white-op p-t-100 p-b-100">
            <Container className="py-0 py-md-5">
              <div className="text-center pb-5">
                <h1 className="pb-3 text-green2">ติดต่อเรา</h1>
                <h5>
                  <span className="pr-2">โทร.</span>
                  <a href="tel:033126411">033-126411, </a>
                  <a href="tel:0994197941">099-4197941,</a>
                  <br />
                  <a href="tel:0994997914">099-4497914</a>
                </h5>
              </div>
              <Row className="pt-3 pb-5">
                <Col lg="6">
                  <img
                    className="img-fluid"
                    src="./asset/images/contact.jpg"
                    alt="contact"
                  />
                </Col>
                <div
                  ref={(ref) => (this.alert_danger = ref)}
                  className="py-lg-0 py-4 col-lg-6"
                >
                  <Alert
                    className="text-center"
                    color="danger"
                    isOpen={this.state.alert !== null}
                    toggle={this.onDismiss}
                  >
                    <i className="fas fa-exclamation-triangle pr-3" />
                    {this.state.alert}
                  </Alert>
                  <FormGroup
                    className={
                      "validate-input " +
                      (this.state.valid.firstname ? "alert-validate" : "")
                    }
                    data-validate="ชื่อไม่ถูกต้อง"
                  >
                    <input
                      className="input-box"
                      value={this.state.data.firstname}
                      type="text"
                      name="firstname"
                      placeholder="ชื่อ"
                      onChange={this.valid}
                      onKeyPress={this.handleEnter}
                    />
                    <span className="focus-input-box"></span>
                    <span className="symbol-input-box">
                      <i className="fas fa-user" />
                    </span>
                  </FormGroup>
                  <FormGroup
                    className={
                      "validate-input " +
                      (this.state.valid.lastname ? "alert-validate" : "")
                    }
                    data-validate="นามสกุลไม่ถูกต้อง"
                  >
                    <input
                      className="input-box"
                      value={this.state.data.lastname}
                      type="text"
                      name="lastname"
                      placeholder="นามสกุล"
                      onChange={this.valid}
                      onKeyPress={this.handleEnter}
                    />
                    <span className="focus-input-box"></span>
                    <span className="symbol-input-box">
                      <i className="fas fa-user" />
                    </span>
                  </FormGroup>
                  <FormGroup
                    className={
                      "validate-input " +
                      (this.state.valid.tel ? "alert-validate" : "")
                    }
                    data-validate="เบอร์ติดต่อไม่ถูกต้อง"
                  >
                    <input
                      className="input-box"
                      type="tel"
                      value={this.state.data.tel}
                      name="tel"
                      placeholder="เบอร์ติดต่อ"
                      onChange={this.valid}
                      onKeyPress={this.handleEnter}
                    />
                    <span className="focus-input-box"></span>
                    <span className="symbol-input-box">
                      <i className="fas fa-phone" />
                    </span>
                  </FormGroup>
                  <FormGroup
                    className={
                      "validate-input " +
                      (this.state.valid.date_register ? "alert-validate" : "")
                    }
                    data-validate="กรุณาเลือกวันที่ขอเข้าชม"
                  >
                    <DatePicker
                      customInput={<this.InputDatePicker />}
                      selected={this.state.data.date_register}
                      onChange={this.date_registerChange}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      timeCaption="Time"
                      locale="th"
                    />
                    <span className="focus-input-box"></span>
                    <span className="symbol-input-box">
                      <i className="fas fa-clock" />
                    </span>
                  </FormGroup>

                  <FormGroup
                    className={
                      "validate-input " +
                      (this.state.valid.email ? "alert-validate" : "")
                    }
                    data-validate="อีเมลไม่ถูกต้อง"
                  >
                    <input
                      className="input-box"
                      type="email"
                      name="email"
                      value={this.state.data.email}
                      placeholder="อีเมล"
                      onChange={this.valid}
                      onKeyPress={this.handleEnter}
                    />
                    <span className="focus-input-box"></span>
                    <span className="symbol-input-box">
                      <i className="fas fa-envelope" />
                    </span>
                  </FormGroup>
                  <FormGroup
                    className={
                      "validate-input " +
                      (this.state.valid.message ? "alert-validate" : "")
                    }
                    data-validate="กรอกรายละเอียด"
                  >
                    <textarea
                      className="textarea-box"
                      type="text"
                      name="message"
                      placeholder="ข้อความ"
                      value={this.state.data.message}
                      onChange={this.valid}
                      onKeyPress={this.handleEnter}
                    />
                    <span className="focus-input-box"></span>
                    <span className="symbol-input-box align-items-start pt-4">
                      <i className="fas fa-envelope-open" />
                    </span>
                  </FormGroup>
                  <FormGroup>
                    <ReCAPTCHA
                      onChange={this.getrecaptcha}
                      ref={recaptchaRef}
                      sitekey="6LcmHnoUAAAAADQykIbH-RqQ3t4CdkYVr-V9pYQ8"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      onClick={() => {
                        this.sendmail();
                      }}
                      color=""
                      className="btn-block bg-brown py-3 text-white hov1"
                    >
                      ตกลง
                    </Button>
                  </FormGroup>
                </div>
              </Row>
              <div className="pt-5" ref={this.props.PageHome.project}>
                <h1 className="text-center pb-4 text-green2">
                  แนวคิดโครงการ K&nbsp;Town
                </h1>
                <p className="pb-3">
                  &emsp;&emsp;ด้วยการตกแต่งสไตล์ Modern Luxury ที่โดดเด่น
                  คิดทุกรายละเอียดของการใช้ชีวิต ตอบโจทย์การใช้งานที่ คุ้มค่าแบบ
                  ทุกตารางเมตร พร้อมทั้งเชื่อมต่อความสุข
                  และความผูกพันของครอบครัว
                </p>
                <p>
                  &emsp;&emsp;K Town คือ Town Home
                  ที่ตอบโจทย์สำหรับผู้ที่ต้องการจะมีบ้านหลังแรก
                  ด้วยฟังก์ชั่นในอารมณ์บ้าน เดี่ยว ภายใต้งบประมาณที่จำกัด
                  โดยทางโครงการได้ออกแบบห้องชั้นล่าง
                  เพื่อให้สามารถเชื่อมต่อความผูกพัน ระหว่าง พ่อ,แม่,ลูก
                  ให้อบอุ่นไปพร้อมกับผู้สูงวัยภายในบ้าน
                </p>
                <hr className="m-t-80 m-b-80 bg-green1" />
                <h1 className="text-center pb-4 text-green2">ข้อมูลโครงการ</h1>
                <Row>
                  <Col xs="12" className="pb-3">
                    &emsp;&emsp;อ.สัตหีบ จ.ชลบุรี จากถนนสุขุมวิท สาย 3 ใกล้ Big
                    C โครงการตั้งอยู่ใน ซอยสัตหีบ 21 อยู่โซนชุมชน แยกเจ
                    หน้าปากซอยมีตลาดสด และร้านสะดวกซื้อเข้าซอยไปไม่เกิน 350ม.
                  </Col>
                  <Col>
                    <Table responsive borderless size="sm">
                      <tbody>
                        <tr>
                          <td className="text-nowrap">เจ้าของโครงการ</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>บริษัท วี อาร์ ดีเวลล็อปเปอร์ จำกัด</td>
                        </tr>
                        <tr>
                          <td className="text-nowrap">ที่ตั้งโครงการ</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>
                            ซอยสัตหีบสุขุมวิท 21 (ซอยสมาคม) ถนนสุขุมวิท
                            ตำบลสัตหีบ อำเภอสัตหีบ จังหวัดชลบุรี 20180
                          </td>
                        </tr>
                        <tr>
                          <td className="text-nowrap">ขนาดพื้นที่โครงการ</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>2 ไร่</td>
                        </tr>
                        <tr>
                          <td className="text-nowrap">จำนวนบ้าน</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>ทาวน์โฮม 26 หลัง</td>
                        </tr>
                        <tr>
                          <td className="text-nowrap">ขนาดที่ดินของบ้าน</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>17.8 ตร.วา ขึ้นไป</td>
                        </tr>
                        <tr>
                          <td className="text-nowrap">หน้ากว้างของบ้าน</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>5.70 เมตร</td>
                        </tr>
                        <tr>
                          <td className="text-nowrap">แบบบ้าน</td>
                          <td width="10%">&nbsp;:&nbsp;</td>
                          <td>
                            แบบบ้านมี 3 แบบ ได้แก่ แบบที่ 1 FLORA, แบบที่ 2
                            TULIPER, แบบที่ 3 LAVENDER
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </section>

        <section
          className="bg-green1 p-t-100 p-b-100"
          ref={this.props.PageHome.house}
        >
          <Container>
            {/* <Row className="justify-content-center">
                            <Col md="6">
                                <FormGroup>
                                    <div className="select-box">
                                        <select value={this.state.select} onChange={(e) => this.changehouseplan(e)}>
                                            {this.state.items.map((items, index) => {
                                                return (
                                                    <option key={`items-${index}`} value={index}>{items.title}</option>
                                                )
                                            })}
                                        </select>
                                        <div className="select_arrow"></div>
                                    </div>
                                    <span className="focus-input-box"></span>
                                    <span className="symbol-input-box"><i className="fas fa-cube" /></span>
                                </FormGroup>
                            </Col>
                        </Row> */}
            {this.state.items.length > 0 &&
              this.state.items.map((items, index) => {
                return (
                  <div>
                    <h3 className="text-yellow text-center pb-4">
                      {items.title}
                    </h3>
                    <p className="text-white">
                      &emsp;&emsp;{items.description}
                    </p>
                    <div className="pt-5 pb-4">
                      <ImageGallery
                        items={items.images}
                        showFullscreenButton={false}
                        lazyLoad={true}
                        showBullets={true}
                        autoPlay={true}
                        showPlayButton={false}
                        slideInterval={5000}
                      />
                      {/* <Swiper {...params}>
                                            {items.images.map((items_image, index) => {
                                                return (
                                                    <div key={`items-${index}`} className="text-center">
                                                        <Col xs="12">
                                                            <img className="img-fluid px-5" src={items_image} alt={items_image} />
                                                        </Col>
                                                    </div>
                                                )
                                            })}
                                        </Swiper> */}
                    </div>
                    <Table
                      responsive
                      borderless
                      size="sm"
                      className="text-white typeHome"
                    >
                      <tbody>
                        <tr>
                          <th width="10%" className="text-nowrap">
                            รายละเอียด
                          </th>
                          <td className="mx-2">&nbsp;:&nbsp;</td>
                          <td>{items.detail}</td>
                        </tr>
                        <tr>
                          <th width="10%" className="text-nowrap">
                            โปรโมชั่น
                          </th>
                          <td className="mx-2">&nbsp;:&nbsp;</td>
                          <td>{items.promotion}</td>
                        </tr>
                        <tr>
                          <th width="10%" className="text-nowrap">
                            ราคา
                          </th>
                          <td className="mx-2">&nbsp;:&nbsp;</td>
                          <td>{items.price}</td>
                        </tr>
                      </tbody>
                    </Table>
                    {index !== 2 && <hr className="bg-white mb-5" />}
                  </div>
                );
              })}
          </Container>
        </section>

        {/* <section className="bg-gray py-2"></section>

                <section className="p-t-100 p-b-100">
                    <Container className="py-0 py-md-5 text-center">
                        <h1 className="text-center pb-4 text-green2">แกลอรี่</h1>
                        <Gallery images={GALLERY_IMAGES.map(({ caption, image_full, orientation, useForDemo, float }) => ({
                            src: image_full,
                            caption,
                            orientation,
                            useForDemo,
                            float
                        }))} />
                    </Container>
                </section> */}

        <section ref={this.props.PageHome.location}>
          <div className="p-t-100 p-b-100">
            <Container>
              <h1 className="text-center pb-4 text-green2 py-3">
                ที่ตั้งโครงการ
              </h1>
              <p className="pb-3">
                &emsp;&emsp;&emsp;อ.สัตหีบ จ.ชลบุรี จากถนนสุขุมวิท สาย 3 ใกล้
                Big C โครงการตั้งอยู่ใน ซอยสัตหีบ 21 อยู่โซนชุมชน แยกเจ
                หน้าปากซอยมีตลาดสด และร้านสะดวกซื้อเข้าซอยไปไม่เกิน 350ม.
              </p>
              <Row>
                <Col xl="6" className="px-lg-5">
                  <Row className="flex-column justify-content-between">
                    <Col xs="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>ห้างสรรพสินค้า</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- BIG C</td>
                            <td>ระยะทาง 0.40 กม.</td>
                          </tr>
                          <tr>
                            <td>- TESCO LOTUS</td>
                            <td>ระยะทาง 3.85 กม.</td>
                          </tr>
                          <tr>
                            <td>- HOME PRO</td>
                            <td>ระยะทาง 8.60 กม.</td>
                          </tr>
                          <tr>
                            <td>- บุญถาวร (กำลังจะเปิด)</td>
                            <td>ระยะทาง 5.85 กม.</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col xl="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>
                                สถานที่ท่องเที่ยวทางธรรมชาติ และแหล่งสันทนาการ
                              </strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- หาดเตยงาม</td>
                            <td>ระยะทาง 7.70 กม.</td>
                          </tr>
                          <tr>
                            <td>- หาดนางรำ</td>
                            <td>ระยะทาง 13.80 กม.</td>
                          </tr>
                          <tr>
                            <td>- หาดบางเสร่</td>
                            <td>ระยะทาง 7.80 กม</td>
                          </tr>
                          <tr>
                            <td className="text-truncate">
                              - สวนน้ำการ์ตูนเน็ทเวิร์ค อเมโซน
                            </td>
                            <td>ระยะทาง 9.58 กม.</td>
                          </tr>
                          <tr>
                            <td>- ไร่องุ่นซิลเวอร์เลค</td>
                            <td>ระยะทาง 17.50 กม.</td>
                          </tr>
                          <tr>
                            <td>- สวนน้ำรามายณะ</td>
                            <td>ระยะทาง 19.10 กม</td>
                          </tr>
                          <tr>
                            <td>- เรือรบหลวงจักรีนฤเบศร์</td>
                            <td>ระยะทาง 15.50 กม</td>
                          </tr>
                          <tr>
                            <td>- เลเจนด์ สยาม</td>
                            <td>ระยะทาง 10.00 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col md="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>สถานที่ออกกำลังกาย</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- สวนสาธารณะหนองตะเคียน</td>
                            <td>ระยะทาง 4.50 กม</td>
                          </tr>
                          <tr>
                            <td>- สวนกีฬาราชนาวี</td>
                            <td>ระยะทาง 8.00 กม</td>
                          </tr>
                          <tr>
                            <td>- สนามกอล์ฟพลูตาหลวง</td>
                            <td>ระยะทาง 14.50 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>ตลาดสด</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- ตลาดสัตหีบ</td>
                            <td>ระยะทาง 5.45 กม</td>
                          </tr>
                          <tr>
                            <td>- ตลาด 700 ไร่</td>
                            <td>ระยะทาง 0.70 กม</td>
                          </tr>
                          <tr>
                            <td>- ตลาดแยกเจ</td>
                            <td>ระยะทาง 2.70 กม</td>
                          </tr>
                          <tr>
                            <td>- ตลาดสหไชย</td>
                            <td>ระยะทาง 4.30 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
                <Col xl="6" className="px-lg-5">
                  <Row className="flex-column">
                    <Col xl="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>แหล่งงาน</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- พัทยา</td>
                            <td>ระยะทาง 25.00 กม</td>
                          </tr>
                          <tr>
                            <td>- บ้านฉาง</td>
                            <td>ระยะทาง 23.00 กม</td>
                          </tr>
                          <tr>
                            <td>- นิคมอุตสาหกรรม มาบตาพุด</td>
                            <td>ระยะทาง 40.00 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>โรงพยาบาล</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- รพ.สมเด็จพระนางเจ้าสิริกิต์</td>
                            <td>ระยะทาง 14.00 กม</td>
                          </tr>
                          <tr>
                            <td>- รพ.อาภากรเกียรติวงศ์</td>
                            <td>ระยะทาง 5.00 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col xs="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>วัดที่มีชื่อเสียง</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- วัดญาณสังวรารามมหาวิหาร</td>
                            <td>ระยะทาง 16.70 กม</td>
                          </tr>
                          <tr>
                            <td>- วัดสัตหีบ (วัดหลวงพ่ออี๋)</td>
                            <td>ระยะทาง 4.70 กม</td>
                          </tr>
                          <tr>
                            <td>- วัดเขาชีจรรย์</td>
                            <td>ระยะทาง 10.50 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col xl="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>สถานศึกษา</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- ร.ร. มารีวิทย์</td>
                            <td>ระยะทาง 2.60 กม</td>
                          </tr>
                          <tr>
                            <td>- ร.ร. สิงห์สมุทร</td>
                            <td>ระยะทาง 3.30 กม</td>
                          </tr>
                          <tr>
                            <td>- ร.ร. ธัมมสิริศึกษา</td>
                            <td>ระยะทาง 1.85 กม</td>
                          </tr>
                          <tr>
                            <td>- ร.ร. เสิศปัญญา</td>
                            <td>ระยะทาง 0.70 กม</td>
                          </tr>
                          <tr>
                            <td>- ร.ร. บ้านเตาถ่าน</td>
                            <td>ระยะทาง 17.50 กม.</td>
                          </tr>
                          <tr>
                            <td>- สวนน้ำรามายณะ</td>
                            <td>ระยะทาง 0.42 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col xl="12">
                      <Table borderless size="sm">
                        <thead>
                          <tr>
                            <td className="pt-4 pb-1 text-green2">
                              <strong>ระบบคมนาคมขนส่ง</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>- สนามบินนานาชาติ อู่ตะเภา</td>
                            <td>ระยะทาง 18.50 กม</td>
                          </tr>
                          <tr>
                            <td>- สถานีขนส่งสัตหีบ</td>
                            <td>ระยะทาง 4.00 กม</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section ref={this.props.PageHome.googlemap}>
          <div className="bg-green2-op">
            <Container>
              <Row className="justify-content-center text-center text-yellow">
                <Col
                  md="4"
                  className="py-4 hov-bg1"
                  style={{ backgroundColor: "#9cbda1" }}
                >
                  <NavLink exact to="/googlemap">
                    <h5 className="py-3">
                      <i className="fas fa-map-marked-alt pr-3" />
                      แผนที่ Google
                    </h5>
                  </NavLink>
                </Col>
                <Col md="4" className="py-4 hov-bg1">
                  <h5
                    className="py-3"
                    onClick={() =>
                      this.setState({
                        lightboxMapIsOpen: !this.state.lightboxMapIsOpen,
                      })
                    }
                  >
                    <i className="fas fa-car-side pr-3" />
                    แผนที่กราฟิก
                  </h5>
                </Col>
                <Lightbox
                  currentImage={this.state.currentlightboxMap}
                  isOpen={this.state.lightboxMapIsOpen}
                  images={MAP_IMAGES}
                  onClickNext={() =>
                    this.setState({
                      currentlightboxMap: this.state.currentlightboxMap + 1,
                    })
                  }
                  onClickPrev={() =>
                    this.setState({
                      currentlightboxMap: this.state.currentlightboxMap - 1,
                    })
                  }
                  onClose={() =>
                    this.setState({
                      lightboxMapIsOpen: !this.state.lightboxMapIsOpen,
                      currentlightboxMap: 0,
                    })
                  }
                />
                <Col
                  md="4"
                  className="py-4 hov-bg1"
                  style={{ backgroundColor: "#9cbda1" }}
                >
                  <a
                    href="https://www.google.com/maps/place/12%C2%B042'19.1%22N+100%C2%B053'33.6%22E/@12.705299,100.8904663,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d12.705299!4d100.892655"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h5 className="py-3">
                      <i className="fas fa-map-marker-alt pr-3" />
                      นำทาง
                    </h5>
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
          <div style={{ height: "50vh", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCelMMEfI_RwOfopS064FVU7NWyu_L3OLE",
              }}
              defaultCenter={{ lat: 12.705306, lng: 100.892667 }}
              defaultZoom={16}
              onGoogleApiLoaded={({ map, maps }) =>
                this.renderMarkers(map, maps)
              }
              yesIWantToUseGoogleMapApiInternals={true}
            ></GoogleMapReact>
          </div>
        </section>
      </div>
    );
  }
}
