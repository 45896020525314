import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export default class Notfound extends Component {
  render() {
    return (
      <div>
        <div className="ab-c-m">
          <h1>ERROR 404</h1>
          <p>Sorry! The page you requested was not found!....</p>
          <Link to="/">
            <Button>Back To Home</Button>
          </Link>
        </div>
      </div>
    );
  }
}
